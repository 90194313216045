import React from "react";
import Layout from "../components/layouts";
import Seo from "../components/seo";
import { useCartStore } from "../state/cart";
import { Link } from "gatsby";

const Cart = () => {
  const {
    lines,
    removeItemFromCart,
    isLoading,
    updateVariantInCart,
    checkoutUrl,
    cost,
  } = useCartStore((state) => state);

  async function handleChangeQuantity(item, quantity) {
    await updateVariantInCart(item.id, item.merchandise.id, quantity);
  }

  const removeItem = async (id) => {
    await removeItemFromCart(id);
  };
  return (
    <Layout navBg="rgba(0,0,0,1)">
      <Seo
        data={{
          title: "Cart",
        }}
      />

      <div className="my-20 pt-4">
        <div className="relative max-w-[1540px] w-full mx-auto box-border show fadeIn my-5 sm:my-10 px-5 lg:px-10">
          <h1 className="text-2xl md:text-4xl font-bold">Cart</h1>
          {lines?.length > 0 ? (
            <div className="grid lg:grid-cols-12 mt-10">
              <div className="lg:col-span-9">
                <table className="flex sm:table flex-col items-center w-full mb-12 border-collapse">
                  <thead className="hidden sm:table-header-group">
                    <tr>
                      <th className="border-t-0 border-b border-solid border-b-[#ebebeb] py-3 text-sm leading-[1.5] text-[#485478] text-left">
                        Product
                      </th>
                      <th className="border-t-0 border-b border-solid border-b-[#ebebeb] py-3 text-sm leading-[1.5] text-[#485478] text-left">
                        Price
                      </th>
                      <th className="border-t-0 border-b border-solid border-b-[#ebebeb] py-3 text-sm leading-[1.5] text-[#485478] text-left">
                        Quantity
                      </th>
                      <th className="border-t-0 border-b border-solid border-b-[#ebebeb] py-3 text-sm leading-[1.5] text-[#485478] text-left">
                        Total
                      </th>
                      <th className="border-t-0 border-b border-solid border-b-[#ebebeb] py-3 text-sm leading-[1.5] text-[#485478] text-left"></th>
                    </tr>
                  </thead>
                  <tbody className="flex sm:table-row-group flex-col items-center w-full mx-auto">
                    {lines.map((item, key) => (
                      <tr
                        key={key}
                        className="relative w-full flex flex-col sm:table-row items-center first-of-type:border-t first-of-type:border-t-[#ebebeb] border-t-0 border-b border-solid border-b-[#ebebeb] py-2"
                      >
                        <td className="py-2 sm:py-8">
                          <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row items-center sm:pr-8">
                            <img
                              src={item.merchandise.image.url}
                              alt={item.merchandise.product.title}
                              className="w-16 h-16 object-contain"
                            />
                            <div className="text-center sm:text-left">
                              <h2 className="text-base font-bold">
                                {item.merchandise.product.title}
                              </h2>
                              <h3 className="text-sm font-medium mt-2">
                                {item.merchandise.title}
                              </h3>
                            </div>{" "}
                          </div>
                        </td>
                        <td className="py-2 sm:py-8">
                          <h3>
                            ${Number(item.merchandise.price.amount).toFixed(2)}
                          </h3>
                        </td>
                        <td className="py-2 sm:py-8">
                          <div className="border border-solid border-secondary text-black text-center flex justify-between items-center space-x-1 px-1">
                            <button
                              className="appearance-none"
                              disabled={isLoading}
                              onClick={() =>
                                handleChangeQuantity(item, item.quantity - 1)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 10Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <span className="px-1">{item.quantity}</span>
                            <button
                              className="appearance-none"
                              disabled={isLoading}
                              onClick={() =>
                                handleChangeQuantity(item, item.quantity + 1)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-4 h-4"
                              >
                                <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td className="py-2 sm:py-8 px-2">
                          <h3>
                            $
                            {(
                              Number(item.merchandise.price.amount) *
                              item.quantity
                            ).toFixed(2)}
                          </h3>
                        </td>
                        <td className="absolute top-3 right-2 sm:relative sm:top-auto sm:right-auto py-2 sm:py-8 px-2">
                          <button
                            className="appearance-none"
                            disabled={isLoading}
                            onClick={() => removeItem(item.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <aside className="lg:col-span-3 px-[10px] relative w-full">
                <div className="bg-[#f9f9f9] border border-solid border-[#d7d7d7] mb-6 px-6 pt-5 pb-6 rounded-sm mt-8">
                  <h4 className="text-base font-semibold pb-4 text-[#111]">
                    Cart Total
                  </h4>

                  <table className="w-full border-collapse leading-[1.86] mb-10">
                    <tbody>
                      <tr>
                        <td className="py-2">Subtotal</td>
                        <td className="py-2 text-right">
                          ${Number(cost?.subtotalAmount.amount).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2">Tax</td>
                        <td className="py-2 text-right">
                          Calculated at checkout
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2">Total</td>
                        <td className="py-2 text-right">
                          ${Number(cost?.totalAmount.amount).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <a
                    href={checkoutUrl}
                    className={`inline-block cursor-pointer px-[20px] leading-[38px] font-extrabold uppercase rounded-tl-[27px] rounded-br-[27px]
                text-center no-underline whitespace-nowrap bg-white btn-secondary border border-solid box-border transition-all duration-200 ease-linear w-full`}
                  >
                    Proceed to Checkout
                  </a>
                </div>
                <Link
                  to="/collections"
                  className={`inline-block cursor-pointer px-[30px] leading-[38px] font-extrabold uppercase rounded-tl-[27px] rounded-br-[27px]
                text-center no-underline whitespace-nowrap bg-white btn-primary border border-solid box-border transition-all duration-200 ease-linear w-full`}
                >
                  Continue Shopping
                </Link>
              </aside>
            </div>
          ) : (
            <div className=" my-10">
              <p className="text-xl sm:text-2xl">No items in the cart</p>
              <Link
                to="/collections"
                className={`inline-block cursor-pointer px-[30px] leading-[38px] font-extrabold uppercase rounded-bl-[27px] rounded-tr-[27px] mt-4
                text-center no-underline whitespace-nowrap bg-white btn-primary border border-solid box-border transition-all duration-200 ease-linear max-w-sm w-full`}
              >
                Continue Shopping
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
